<template>
  <div
    class="fill-height"
  >
    <div class="vid">
      <div class="vid-title">
        <div class="vid-title-wrapper">
          <div
            :style="[
              video && video.setting && video.setting.titleColor
                ? { color: video.setting.titleColor }
                : ''
            ]"
          >
            {{ video && video.name ? video.name : null }}
          </div>
<!--          <img-->
<!--            class="vid-title-yuesai-avatar"-->
<!--            width="70px"-->
<!--            src="@/assets/images/yuesai_avatars/avatar_1.png"-->
<!--            :alt="`Yuesai Kan video ${video && video.name ? video.name : ''}`"-->
<!--          />-->
        </div>
      </div>
      <div class="vid-video mt-10">
        <nut-video
          v-if="video"
          :sources="video.sources"
          :options="video.options"
          @play="play"
          @pause="pause"
          @playend="playend"
        >
        </nut-video>
      </div>
<!--      <div class="vid-content-wrapper mt-55">-->
<!--        <div class="vid-content-wrapper-body">-->
<!--          <nut-scroller-->
<!--            class="vid-content-wrapper-body-scroller"-->
<!--            :type="'vertical'"-->
<!--            :is-un-more="true"-->
<!--            pulldown-txt=""-->
<!--          >-->
<!--            <div-->
<!--              slot="list"-->
<!--              :style="[-->
<!--                video && video.setting && video.setting.contentColor-->
<!--                  ? { backgroundColor: video.setting.contentColor }-->
<!--                  : ''-->
<!--              ]"-->
<!--            >-->
<!--              {{ video && video.introduction ? video.introduction : null }}-->
<!--            </div>-->
<!--          </nut-scroller>-->
<!--        </div>-->
<!--        <div class="vid-content-wrapper-buttons">-->
<!--          <div @click="thumbUp">-->
<!--            <nut-icon-->
<!--              type="self"-->
<!--              size="35px"-->
<!--              :url="!video.if_liked ? buttons.thumbUp : buttons.thumbUpActive"-->
<!--            ></nut-icon>-->
<!--          </div>-->
<!--          <div class="mt-45" @click="share">-->
<!--            <nut-icon type="self" size="35px" :url="buttons.share"></nut-icon>-->
<!--          </div>-->
<!--          <div class="mt-45" @click="addToFavorite">-->
<!--            <nut-icon-->
<!--              type="self"-->
<!--              size="35px"-->
<!--              :url="!video.if_favorited ? buttons.fav : buttons.favActive"-->
<!--            ></nut-icon>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
<!--    <share-sheet-->
<!--      :visible="shareSheetVisible"-->
<!--      :share-info="shareInfo"-->
<!--      v-on:onCloseShareSheet="onCloseShareSheet"-->
<!--      v-on:onClickShowShareGuidance="onClickShowShareGuidance"-->
<!--    />-->
<!--    <share-overlay-->
<!--      :show="shareOverlayVisible"-->
<!--      v-on:onCloseShareOverlay="onCloseShareOverlay"-->
<!--    />-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VideoService from "@/services/video.service";
import { WxMixin } from "@/mixins/wx";
// import ShareSheet from "@/components/video/shareSheet";
// import ShareOverlay from "@/components/common/shareOverlay";
const ThumbUp = require("../assets/icons/thumbup.svg");
const ThumbUpActive = require("@/assets/icons/thumbup_active.svg");
const Share = require("@/assets/icons/share.svg");
const ShareActive = require("@/assets/icons/share_active.svg");
const Fav = require("@/assets/icons/fav.svg");
const FavActive = require("@/assets/icons/fav_active.svg");

export default {
  name: "Video",

  mixins: [WxMixin],

  components: {
    // ShareSheet,
    // ShareOverlay
  },

  title() {
    return `${this.$t("video.pageTitle")}: ${
      this.video ? this.video.name : "章节视频"
    }`;
  },

  data() {
    return {
      shareSheetVisible: false,
      shareOverlayVisible: false,
      shareInfo: null,
      options: {
        controls: true
      },
      buttons: {
        thumbUp: ThumbUp,
        thumbUpActive: ThumbUpActive,
        share: Share,
        shareActive: ShareActive,
        fav: Fav,
        favActive: FavActive
      }
    };
  },

  computed: {
    ...mapGetters("video", {
      video: "getVideo"
    })
  },

  watch: {
    video(o) {
      console.log(o);
    }
  },

  created() {
    this.setVideo(this.$route.params.id).then(res => {
      document.title = `${this.$t("video.pageTitle")}: ${res.name}`;
    });
  },

  methods: {
    share() {
      this.shareInfo = {
        title: this.video ? this.video.name : "dad", // this.video.title,
        desc: this.video ? this.video.introduction : "awd", //this.video.introduction,
        link: window.location.href, //window.location,
        imgUrl: this.video ? this.video.options.poster : "awdw" //this.video.options.poster
      };

      this.shareSheetVisible = true;
    },

    addToFavorite() {
      const videoId = this.$route.params.id;

      VideoService.toggleFavorite(videoId).then(res => {
        this.setVideoFavStatus({
          status: res.data.if_favorited
        }).then(() => {
          this.$toast.success(
            res.data.if_favorited ? "已加入收藏" : "已移除收藏"
          );
        });
      });
    },

    thumbUp() {
      const videoId = this.$route.params.id;

      VideoService.thumbUp(videoId).then(res => {
        this.setVideoLikeStatus({ status: res.data.if_liked });
      });
    },

    play(elm) {
      console.log("play", elm);
    },
    pause() {
      console.log("pause");
    },
    playend() {
      alert("播放结束");
    },

    onCloseShareSheet() {
      this.shareSheetVisible = false;
    },

    onCloseShareOverlay() {
      this.shareOverlayVisible = false;
    },

    onClickShowShareGuidance() {
      this.shareOverlayVisible = true;
      this.shareSheetVisible = false;
    },
    ...mapActions("video", [
      "setVideo",
      "setVideoLikeStatus",
      "setVideoFavStatus"
    ])
  }
};
</script>

<style lang="scss" scoped>
.primary-bg {
  background-image: url("../assets/images/background.jpeg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}
.vid {
  height: calc(100% - 60px);
  overflow-y: scroll;
  padding-top: 10px;
  &-title {
    display: flex;
    justify-content: center;
    &-yuesai-avatar {
      position: absolute;
      bottom: 0;
      right: 15px;
    }
    &-wrapper {
      width: 85%;
      position: relative;
      background-color: whitesmoke;
      padding: 9px 12px;
      font-weight: bold;
      text-align: left;
      border-top-right-radius: 15px;
      border-bottom-left-radius: 15px;
    }
  }

  &-video {
  }

  &-content-wrapper {
    display: flex;
    justify-content: space-around;
    padding-left: 5%;
    padding-bottom: 70px;
    &-buttons {
      width: 20%;
    }
    &-body {
      width: 80%;
      height: 165px;
      text-align: left;
      background-color: #f2f2f1;
      border-top-right-radius: 15px;
      border-bottom-left-radius: 15px;
      padding: 15px 19px;
      color: #923944;
      &-scroller {
        .nut-vert-loadmore {
          display: none;
        }
      }
    }
  }
}
</style>
